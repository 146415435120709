<template>

  <template>
    <div class='text-center'>
      <v-dialog
        v-model='dialog'
        width='500'
      >
        <v-card>
          <v-card-title class='text-h5 grey lighten-2'>
            <rn-ws-text path='razomy.notation.editor.web.app.title.en'></rn-ws-text>
          </v-card-title>

          <v-card-text ref='text'>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              text
              @click='dialog = false'
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>

  <!--    fab-->
  <v-btn size='small'
         position='fixed'
         location='right top'
         icon='mdi-account'
         @click='dialog = true; load()'
  >
  </v-btn>

</template>
<script>

import rnWsText from '@/components/rn-ws-text.vue';
import { PackageVersion } from '@/components/packageVersion';
import { createElementFromHTML } from '@/components/editor/plugins/rn_monaco_iframe';

window.customElements.define('package-version', PackageVersion);

function load(ctx, el$) {
  var newDrawer = document.createElement('package-version');
  el$.appendChild(newDrawer);
  el$.appendChild(createElementFromHTML(`<div>plugins:${
    ctx.rn_config.registry.map(i => `<div>  ${i.selector}</div>`).join('')
  }</div>`));
}

export default {
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    rnWsText,
  },
  methods: {
    load() {
      setTimeout(i => {
        load(window.editorContext, this.$refs.text.$el);

      }, 3);
    },
  },
};

</script>
