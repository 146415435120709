import { isClassConstructor } from 'razomy.ecmascript/dist/Operations/IsClassConstructor';
import ctx_component from '@/components/editor/plugins/a_ctx_component';
import _ from 'lodash';

export default class plugin_controller extends ctx_component {
  public static readonly key: string = 'plugin_controller';
  private plugins: { key, value }[] = [];

  constructor(ctx: Ctx) {
    super(ctx);
  }

  load(pluginClassOrFunctionOrObject) {
    if (isClassConstructor(pluginClassOrFunctionOrObject)) {
      const obj = new pluginClassOrFunctionOrObject(this.ctx);
      const key = pluginClassOrFunctionOrObject.key;

      if (!key) {
        console.error('Key mus be defined.' + pluginClassOrFunctionOrObject);
        return;
      }
      this.ctx[key] = obj;
      this.plugins.push({ key, value: obj });
      return obj;
    }

    throw new Error('Unknown type');
  }

  unload(pluginClassOrFunctionOrObject) {
    const key = pluginClassOrFunctionOrObject.key;
    delete this.ctx[key];
    _.remove(this.plugins, { key });
  }
}
