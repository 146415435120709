<template>
  <notation-editor v-if='session'></notation-editor>

  <div v-else>
    <v-progress-circular indeterminate color='primary'></v-progress-circular>
  </div>

  <account-button></account-button>
</template>

<script>
import NotationEditor from '@/components/notation-editor.vue';
import AccountButton from '@/components/account-button.vue';

export default {
  computed: {
    session() {
      return this.$cookies.isKey('g_state');
    },
  },
  components: {
    AccountButton,
    NotationEditor,
  },
};
</script>
