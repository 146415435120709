<template>{{resultText}}</template>

<script>
import { rn_api_ws_value } from '@/components/editor/plugins/rn_api_ws_value';

export default {
  data() {
    return {
      resultText: '',
    };
  },
  props: {
    path: {
      type: String,
      required: true
    }
  },
  created() {
    const rnApiValue = new rn_api_ws_value(window.editorContext, this.$props.path);
    rnApiValue.on().subscribe(i => {
      this.resultText = i;
    });
  },
};
</script>
