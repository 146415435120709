
import start_finish from '@/components/editor/plugins/start_finish';
import rn_plugin_controller from '@/components/editor/plugins/rn_plugin_controller';
import plugin_controller from '@/components/editor/plugins/plugin_controller';
import ctx_controller from '@/components/editor/plugins/ctx_controller';

declare global {
  interface Window {
    editorContext: Ctx;
  }
}

export default {
  mounted() {
    this.$nextTick(async () => {
      this.ctx = ctx_controller.create();
      this.ctx.html_element = this.$el;
      this.ctx.start_finish = new start_finish(this.ctx);
      this.ctx.plugin_controller = new plugin_controller(this.ctx);
      this.ctx.rn_plugin_controller = new rn_plugin_controller(this.ctx);
      this.ctx.start_finish.startFinish.next(true);
      window.editorContext = this.ctx;
    });
  },
  unmounted() {
    this.ctx.start_finish.startFinish.next(false);
  },
};
