<template><div></div></template>
<script>
import { useTheme } from 'vuetify';
import { Subscription } from 'rxjs';
import *  as monaco from 'monaco-editor';

const subscription = new Subscription();
export default {
  setup() {
    const theme = useTheme();
    subscription.add(
      notation.system.theme.isBrowserPreferDark$.subscribe((i) => {
        theme.global.name.value = i ? 'dark' : 'light';
      }),
    );

    subscription.add(
      notation.system.theme.isBrowserPreferDark$.subscribe((i) => {
        monaco.editor.setTheme(i ? 'vs-dark' : 'vs');
      }),
    );
  },
  unmounted() {
    subscription.unsubscribe();
  },
};
</script>
