import axios from 'axios';
import { fromEvent, Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

export default window.notation = {
  system: {
    theme: {
      isBrowserPreferDark$: fromEvent(
        window.matchMedia('(prefers-color-scheme: dark)'),
        'change',
      ).pipe(
        startWith(window.matchMedia('(prefers-color-scheme: dark)')),
        map((e) => !!e.matches),
      ),
    },
  },
  start() {
    return Promise.resolve({});
  },
};
