import { createApp } from "vue";
import App from "./app.vue";

import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import "@mdi/font/css/materialdesignicons.css";
import * as directives from "vuetify/directives";
import router from "./router";
import Notation from "@/start";

declare global {
  interface Window { notation: any; }
}

import vue_cookies from 'vue-cookies';

const vuetify = createVuetify({
  components,
  directives,
});

const main = async () => {
  const notation = Notation;
  await notation.start();

  const metaPlugin = {
    install(app) {
      app.config.globalProperties.notation = window.notation;
    },
  };

  return createApp(App)
    .use(router)
    .use(vuetify)
    .use(metaPlugin)
    .use(vue_cookies)
    .mount("#app");
};

main().then();
