import { Subscriber } from 'rxjs';

export default abstract class a_ctx_component {
  subscriber: Subscriber<void>;

  constructor(protected ctx: Ctx) {
    this.ctx = ctx;
    this.subscriber = new Subscriber();
  }

  destructor(ctx: Ctx) {
    this.subscriber.unsubscribe();
  }
}
