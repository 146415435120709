import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import _ from 'lodash';
import ctx_component from '@/components/editor/plugins/a_ctx_component';

function executeFn(obj, nameFn, _arguments = null, error = undefined) {
  if (obj[nameFn] && obj[nameFn] instanceof Function) {
    // eslint-disable-next-line prefer-spread
    return obj[nameFn].apply(obj, _arguments);
  }

  return error;
}

function executeFnInCtx(ctx, nameFn, _arguments = null) {
  let errorexecuteinctx = 'ERROR_EXECUTE_IN_CTX';
  const res = _.map(ctx, (plugin) => {
    return executeFn(plugin, nameFn, _arguments, errorexecuteinctx);
  });

  return _.filter(res, i => i !== errorexecuteinctx);
}

export default class start_finish extends ctx_component {
  public static readonly key: string = 'start_finish';

  startFinish: BehaviorSubject<boolean>;
  start$: Observable<boolean>;
  finish$: Observable<boolean>;
  ctx_start_finish_start: 'ctx_start_finish_start';
  ctx_start_finish_finish: 'ctx_start_finish_finish';

  constructor(ctx: Ctx) {
    super(ctx);
    this.startFinish = new BehaviorSubject(null);
    this.start$ = this.startFinish.pipe(filter(i => i === true));
    this.finish$ = this.startFinish.pipe(filter(i => i === false));
    this.ctx_start_finish_start = 'ctx_start_finish_start';
    this.ctx_start_finish_finish = 'ctx_start_finish_finish';

    this.subscriber.add(this.startFinish.pipe(tap((i) => {
      const isStart = this.startFinish.value;
      if (isStart === null) {
        return;
      }
      if (isStart === true) {
        executeFnInCtx(this.ctx, this.ctx_start_finish_start);
      } else if (isStart === false) {
        executeFnInCtx(this.ctx, this.ctx_start_finish_finish);
      }
    })).subscribe());

    this.subscriber.add(this.ctx.ctx_controller.add$.subscribe(({ key, value }) => {
      const isStart = this.startFinish.value;
      if (isStart === null) {
        return;
      }
      if (isStart === true) {
        executeFn(value, this.ctx_start_finish_start);
      } else if (isStart === false) {
        // nothing
      }
    }));

    this.subscriber.add(this.ctx.ctx_controller.remove$.subscribe(({ key, value }) => {
      const isStart = this.startFinish.value;
      if (isStart === null) {
        return;
      }
      if (isStart === true) {
        // nothing
      } else if (isStart === false) {
        executeFn(value, this.ctx_start_finish_finish);
      }
    }));
  }

  execute(name, args = null) {
    return executeFnInCtx(this.ctx, name, args);
  }
}
