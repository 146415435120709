export class PackageVersion extends HTMLElement {

  constructor() {
    super();
    this.packageVersion = process.env.PACKAGE_VERSION || '0';
  }

  connectedCallback() {
    this.textContent = `
  version: ${this.packageVersion}
  `;
  }

}
