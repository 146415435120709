<template>
  <div
    id='g_id_onload'
    data-client_id='104553962015-q818tj1upgha4bhfnepfn37s1lfmcvvc.apps.googleusercontent.com'
    data-context='use'
    data-ux_mode='popup'
    data-login_uri='/api/auth/sign/google'
    data-nonce=''
    data-skip_prompt_cookie='g_state'
    data-auto_select='true'
    data-itp_support='true'
  ></div>
  <component
    v-bind:is="'script'"
    src='https://accounts.google.com/gsi/client'
    async
    defer
  ></component>
</template>
