export function createViewZone(editor, position, url, cb) {
  const height = 350;
  const frame = createElementFromHTML(createViewZoneIFrame(height));
  frame.querySelector('iframe').setAttribute('src', url);
  const zone = {
    afterLineNumber: position.lineNumber,
    heightInPx: height,
    domNode: frame,
  };
  editor.changeViewZones((changeAccessor) => {
    cb(changeAccessor.addZone(zone));
  });
}

function createViewZoneIFrame(height) {
  return `
   <div style='width:100%;z-index:1;height:${height}px;user-select: auto'>
     <iframe width='100%' style='border:none' height='100%'></iframe>
   </div>
  `;
}

function createViewZoneIFrameStyles() {
  return `
<style>
    html {
        color: #d4d4d4;
        background: rgba(30, 30, 30);
    }
</style>
`;
}

export function createViewZoneWidgetFromHtml(height, htmlString) {
  const frameContainer = createElementFromHTML(createViewZoneIFrame(height));
  const src = `
<html>
<head>${createViewZoneIFrameStyles()}</head>
<body style='overflow: scroll; max-height: ${height}px'>${htmlString}</body>
</html>
`;
  frameContainer.querySelector('iframe').srcdoc = src;

  return frameContainer;
}

export function createElementFromHTML(htmlString) {
  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  return div.firstChild as HTMLElement;
}


export function removeFirst(n) {
  return n.shift();
}

export function safeCallback(cb) {
  try {
    return cb();
  } catch (e) {
    console.error(e);
  }
}
