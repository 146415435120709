import { Subject } from 'rxjs';
import a_ctx_component from '@/components/editor/plugins/a_ctx_component';

export default class ctx_controller extends a_ctx_component {
  public static readonly key: string = 'ctx_controller';
  add$: Subject<{ key, value }>;
  remove$: Subject<{ key, value }>;
  proxy: typeof Proxy;

  constructor(pureCtx) {
    super(pureCtx);
    this.add$ = new Subject();
    this.remove$ = new Subject();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.proxy = new Proxy(pureCtx, {
      set(target, key, value) {
        target[key] = value;
        self.add$.next({ key, value });
        return true;
      },
      deleteProperty(target, key) {
        const value = target[key];
        self.remove$.next({ key, value });
        delete target[key];
        return true;
      },
    });
  }

  static create() {
    const ctx: Ctx = {};
    ctx.ctx_controller = new ctx_controller(ctx);
    return ctx.ctx_controller.proxy;
  }
}
