import a_ctx_component from '@/components/editor/plugins/a_ctx_component';

export default class rn_plugin_controller extends a_ctx_component {
  public static readonly key: string = 'rn_plugin_controller';

  constructor(ctx: Ctx) {
    super(ctx);
  }

  async ctx_start_finish_start() {
    const arr = await Promise.all([
      import('@/components/editor/plugins/css_manager'),
      import('@/components/editor/plugins/notifications'),
      import('@/components/editor/plugins/popup'),
      import('@/components/editor/plugins/db_indexed_db'),
      import('@/components/editor/plugins/text_controller'),
      import('@/components/editor/plugins/rn'),
      import('@/components/editor/plugins/rn_api_ws'),
      import('@/components/editor/plugins/rn_api'),
      import('@/components/editor/plugins/rn_config'),
      import('@/components/editor/plugins/rn_editor'),
      import('@/components/editor/plugins/rn_editor_monaco'),
      import('@/components/editor/plugins/rn_editor_monaco_language'),
      import('@/components/editor/plugins/rn_editor_state'),
      import('@/components/editor/plugins/rn_local_backup'),
      import('@/components/editor/plugins/rn_server_version'),
      import('@/components/editor/plugins/rn_textchart'),
      import('@/components/editor/plugins/ai'),
      import('@/components/editor/plugins/rn_monaco_save'),
      import('@/components/editor/plugins/diagram'),
    ]);

    arr.forEach((i) => this.ctx.plugin_controller.load(i.default));
  }
}
