import { concat, from, map, Observable, shareReplay } from 'rxjs';
import a_ctx_component from '@/components/editor/plugins/a_ctx_component';

export class rn_api_ws_value<T> extends a_ctx_component {
  constructor(ctx: Ctx, private readonly path) {
    super(ctx);
  }

  public on(): Observable<T> {
    const get = from(this.ctx.rn_api.getBy(this.path))
      .pipe(map(i => i.data));
    const on = this.ctx.rn_api_ws.client.onMessage(this.path, 'change');
    this.ctx.rn_api_ws.client.send(this.path, 'create', { timeout: 500 });
    return concat(get, on)
      .pipe(
        shareReplay(1),
      );
  }
}
