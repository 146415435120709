import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../pages/home-view.vue';
import axios from 'axios';

const checkAuthenticated = async (to, from, next) => {
  return axios
    .get('/api/auth/get', {})
    .then(() => {
      return next();
    })
    .catch(() => {
      return next('/login');
    });
};

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: checkAuthenticated,
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/pages/about-view.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login-view.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'Not Found',
    component: () => import('@/pages/not-found.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
